<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
          
        <div class="col-12 col-md-8">
          <h3 class="mb-0 text-center">Masa Doluluk Oranları</h3>
          <span hidden class="bold masa-doluluk-font mr-1">Dolu Masa:</span> <span hidden class="masa-doluluk-font mr-2">{{ doluMasaSayisi }}</span>
          <span hidden class="bold masa-doluluk-font mr-1">Boş Masa:</span> <span hidden class="masa-doluluk-font mr-2">{{ bosMasaSayisi }}</span>
          <span hidden class="bold masa-doluluk-font mr-1">Servis Dışı:</span> <span hidden class="masa-doluluk-font">{{ servisDisi }}</span>
          <div hidden v-if="doluMasa || bosMasa" class="masa-doluluk-bant pt-3">
              <div title="Dolu Masa" :style="`float: left; width:${doluMasa}%`" class="bg-danger text-white text-center">{{ `${doluMasa} %` }}</div>
              <div title="Boş Masa" :style="`float: left; width:${bosMasa}%`" class="bg-success text-white text-center">{{ `${bosMasa} %` }}</div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label for="masa-doluluk-oranlari">Şubeler</label>
          <select @change="masaDolulukOranlariGetir" v-model="subeID" class="form-control" id="masa-doluluk-oranlari">
              <option value="0">Tümü</option>
              <option v-for="(sube, index) in subeler" :key="index" :value="sube.MusteriID">{{ sube.FirmaAdi }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
        <div v-if="loading" class="col-12 col-md-4">
            <h2 class="text-center">Grafikler Yükleniyor</h2>
        </div>
        <div v-else class="col-12 col-md-4">
            <masa-doluluk-chart type="pie" id="masa-doluluk-chart" ref="masaDolulukChart" :chart-data="masaDolulukData" :labels="labels" :background-color="backgroundColor" :options="options"/>
        </div>

        <div class="col-12 col-md-8">
            <div class="table-responsive">
                <table class="table table-hover table-bordered">
                    <thead>
                        <tr>
                            <th>Şube Adı</th>
                            <th>Masa Adı</th>
                            <th>Saat</th>
                            <th>Toplam Tutar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(masa, index) in acikMasalar" :key="index">
                            <td>{{ subeAdiGetir(masa.MusteriNo) }}</td>
                            <td>{{ masa.MasaAdi }}</td>
                            <td>{{ masa.Saat }}</td>
                            <td>{{ paraFormatla(masa.ToplamTutar) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
    </div>
    

  </div>
</template>
<script>

  import api from '@/api/api'
  import paraFormatla from '@/utils/paraFormatla'

  import MasaDolulukChart from '../Charts/BaseChart'

  export default {
    name: 'MasaDolulukOrani',
    components: {
        MasaDolulukChart
    },
    data() {
      return {
        subeID: 0,
        subeler: [],
        masaBilgileri: {},
        acikMasalar: [],
        masaSayisi: 0,
        doluMasa: 0,
        bosMasa: 0,
        servisDisi: 0,
        doluMasaSayisi: 0,
        bosMasaSayisi: 0,
        interval: null,
        options: {
            legend: {
                display: true,
                position: 'top'
            },
            showAllTooltips: false,
            responsive: true, 
            maintainAspectRatio: true,
        },
        labels: ['Boş Masa', 'Dolu Masa', 'Servis Dışı'],
        masaDolulukData: [],
        backgroundColor: ['red', 'green', 'black'],
        loading: true
      }
    },
    mounted() {
        this.subelerGetir()
        this.interval = setInterval(() => {
            this.masaDolulukOranlariGetir()
        }, 60000)
    },
    methods: {
        subelerGetir() {
            const subelerData = new FormData()

            const { MusteriNo } = api.getUser()

            subelerData.append('MusteriNo', MusteriNo)

            api
            .subelerGetir(subelerData)
            .then(resp => resp.json())
            .then(data => {
                this.subeler = data[0]
                this.masaDolulukOranlariGetir()
            })
        },
        masaDolulukOranlariGetir() {
            const subelerData = new FormData()

            const { MusteriNo } = api.getUser()

            if (!MusteriNo) {
                this.interval = null
                return
            }

            if (this.subeID > 0) {
                subelerData.append('MusteriNo[]', this.subeID)
            } else {
                this.subeler.forEach(sube => {
                    subelerData.append('MusteriNo[]', sube.MusteriID)
                })
            }

            api
             .masaDolulukOranlariGetir(subelerData)
             .then(resp => resp.json())
             .then(data => {
                 
                 this.masaBilgileri = data.masa_bilgileri[0]
                 this.acikMasalar = data.acik_masalar

                 this.masaDolulukData = [this.masaBilgileri.BosMasa, this.masaBilgileri.DoluMasa, this.masaBilgileri.ServisDisi]

                 this.labels = [
                     `Boş Masa (${this.masaBilgileri.BosMasa})`, 
                     `Dolu Masa (${this.masaBilgileri.DoluMasa})`, 
                     `Servis Dışı (${this.masaBilgileri.ServisDisi})`
                 ]

                 this.loading = false

                //  const doluMasa = parseFloat((this.masaBilgileri.DoluMasa * 100) / this.masaBilgileri.MasaSayisi).toFixed(2)
                //  const bosMasa = parseFloat((this.masaBilgileri.BosMasa * 100) / this.masaBilgileri.MasaSayisi).toFixed(2)

                //  this.doluMasaSayisi = this.masaBilgileri.DoluMasa
                //  this.bosMasaSayisi = this.masaBilgileri.BosMasa
                //  this.servisDisi = this.masaBilgileri.ServisDisi

                //  this.doluMasa = doluMasa
                //  this.bosMasa = bosMasa
                 
             })
        },
        paraFormatla(sayi) {
            return paraFormatla(sayi)
        },
        subeAdiGetir(musteriNo) {
            const subeObject = this.subeler.find(sube => sube.MusteriID == musteriNo)

            let subeAdi = '-'

            if (subeObject) {
                subeAdi = subeObject.FirmaAdi                
            }

            return subeAdi
        }
    }
  }
</script>
<style>
</style>
