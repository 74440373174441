<template>
    <div>
        <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-5">
            <!-- Card stats -->
            <div class="row">
                <div @click="onSubeClick" v-for="(data, index) in boxData" :key="index" class="col-xl-4 col-lg-6 mb-md-2">
                    <stats-card :title="data.Firma"
                                type="gradient-red"
                                :sub-title="paraFormatla(data.Gelir)"
                                icon="fas fa-lira-sign"
                                :class="'mb-4 mb-xl-0 cursor-ptr'"
                    >

                        <template slot="footer">
                            <span class="text-nowrap">{{ zamanAdi }}</span>
                        </template>
                    </stats-card>
                </div>
            </div>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 mb-5 mb-xl-0">
                    <card header-classes="bg-transparent">
                        <div slot="header" class="row align-items-center">
                            <div :class="zaman == 7 ? 'col-4' : 'col-6'">
                                <h6 class="text-uppercase text-muted ls-1 mb-1">Gelir</h6>
                                <h5 class="h3 mb-0">Toplam Gelir</h5>
                            </div>
                            <div :class="zaman == 7 ? 'col-4' : 'col-6'">
                                <label for="tarih-araligi">Tarih Aralığı</label>
                                <select v-model="zaman" @change="zamanaGoreFiltrele" id="tarih-araligi" class="form-control">
                                    <option value="1">Bugün</option>
                                    <option value="2">Dün</option>
                                    <option value="3">Bu Hafta</option>
                                    <option value="4">Geçen Hafta</option>
                                    <option value="5">Bu Ay</option>
                                    <option value="6">Geçen Ay</option>
                                    <option value="7">Özel</option>
                                </select>
                            </div>
                            <div v-if="zaman == 7" class="col-4">
                                <label class="col-md-6 mt-2" for="tarih-1">Başlangıç Tarihi <input @input="zamanaGoreFiltrele" class="form-control mt-2" type="date" v-model="tarih1"/></label>
                                    
                                <label class="col-md-6 mt-2" for="tarih-2">Bitiş Tarihi <input @input="zamanaGoreFiltrele" class="form-control mt-2" type="date" v-model="tarih2"/></label>
                            </div>
                        </div>

                        <bar-chart
                                :height="350"
                                ref="barChart"
                                :chart-data="gelirBarChart.chartData"
                        >
                        </bar-chart>
                    </card>
                </div>
            </div>
            <!-- End charts-->

            <!--Tables-->
            <div class="row mt-5">
                <div class="col-xl-8 mb-5 mb-xl-0">
                    <masa-doluluk-orani />
                </div>
                <div class="col-xl-4">
                    <dashboard-subeler />
                </div>
            </div>
            <!--End tables-->
        </div>

    </div>
</template>

<script>

  import moment from 'moment'

  import api from '@/api/api'

  // Charts
  import BarChart from '@/components/Charts/BarChart';

  // Tables
  import DashboardSubeler from './Dashboard/DashboardSubeler';
  import MasaDolulukOrani from './Dashboard/MasaDolulukOrani';

  import paraFormatla from '@/utils/paraFormatla'
  

  export default {
    name: 'Dashboard',
    components: {
      BarChart,
      MasaDolulukOrani,
      DashboardSubeler,
    },
    data() {
      return {
        boxData: [],
        interval: null,
        zaman: 3,
        zamanAdi: '',
        tarih1: moment().format('YYYY-MM-DD'),
        tarih2: moment().format('YYYY-MM-DD'),
        gelirBarChart: {
          chartData: {
            labels: [],
            datasets: [{
              label: 'Gelir',
              data: []
            }]
          }
        }
      };
    },
    methods: {
      onSubeClick() {
          
      },
      initBigChart(subeler, gelirler) {
        let chartData = {
            labels: subeler,
            datasets: [{
                label: 'Gelir',
                data: gelirler
            }]
        };
        this.gelirBarChart.chartData = chartData;
        this.gelirBarChart.activeIndex = 0;
      },
      zamanaGoreFiltrele() {
          // 1 = bugün, 2 = dün, 3 = bu hafta, 4 = geçen hafta, 5 = bu ay, 6 = geçen ay
          if (this.zaman != 7) {
              this.tarih1 = moment().format('YYYY-MM-DD')
              this.tarih2 = moment().format('YYYY-MM-DD')
          }
          this.dashboardYukle()
      },
      zamanAdiYukle() {
          
          switch (this.zaman.toString()) {
            case '1':
                this.zamanAdi = 'Bugün'
                break;
            case '2':
                this.zamanAdi = 'Dün'
                break;
            case '3':
                this.zamanAdi = 'Bu Hafta'
                break;
            case '4':
                this.zamanAdi = 'Geçen Hafta'
                break;
            case '5':
                this.zamanAdi = 'Bu Ay'
                break;
            case '6':
                this.zamanAdi = 'Geçen Ay'
                break;
            case '7':
                this.zamanAdi = `${this.tarih1} ve ${this.tarih2} arasında`
                break;
            default:
                this.zamanAdi = 'Bu Hafta'
                break;
        }
      },
      dashboardYukle() {
        
        const dashboardData = new FormData()

        const { MusteriNo } = api.getUser()

        if (!MusteriNo) {
            this.interval = null
            return
        }

        dashboardData.append('MusteriNo', MusteriNo)
        dashboardData.append('zaman', this.zaman)
        dashboardData.append('baslangic-tarihi', this.tarih1)
        dashboardData.append('bitis-tarihi', this.tarih2)

        this.zamanAdiYukle()

        api
        .dashboard(dashboardData)
        .then(resp => resp.json())
        .then(data => {
            this.boxData = data[0]
            
            const subeler = this.boxData.map(sube => sube.Firma)
            const gelirler = this.boxData.map(gelir => gelir.Gelir ? parseFloat(gelir.Gelir).toFixed(2) : '0.00')

            this.initBigChart(subeler, gelirler);
        })
      },
      paraFormatla(sayi) {
        return paraFormatla(sayi)
      }
    },
    mounted() {
      this.dashboardYukle()
      this.interval = setInterval(() => {
          this.dashboardYukle()
      }, 60000)
    },

  };
</script>
<style></style>
