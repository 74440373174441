<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col-12 col-md-6">
          <h3 class="mb-0">Şube Gelirleri</h3>
        </div>
        <div class="col-12 col-md-6">
          <label for="sube-gelirleri">Şubeler</label>
          <select @change="subeKutularGetir" v-model="subeID" class="form-control" id="sube-gelirleri">
              <option value="0">Tümü</option>
              <option v-for="(sube, index) in subeler" :key="index" :value="sube.MusteriID">{{ sube.FirmaAdi }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-hover table-bordered">
          <thead>
              <tr>
                  <th>Zaman</th>
                  <th>Gelir</th>
                  <th>Gider</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="(gelir, index) in subeGelirleri" :key="index">
                  <td>{{ gelir.zaman }}</td>
                  <td>{{ gelir.gelir }}</td>
                  <td>{{ gelir.gider }}</td>
              </tr>
          </tbody>
      </table>
    </div>

  </div>
</template>
<script>

  import api from '@/api/api'

  import paraFormatla from '@/utils/paraFormatla'

  export default {
    name: 'DashboardSubeler',
    data() {
      return {
        subeID: 0,
        subeler: [],
        subeGelirleri: [],
        interval: null
      }
    },
    mounted() {
        this.subelerGetir()
        this.interval = setInterval(() => {
            this.subeKutularGetir()
        }, 60000)
    },
    methods: {
        subeKutularGetir() {

            const { MusteriNo } = api.getUser()

            if (!MusteriNo) {
                this.interval = null
                return
            }
            
            const kutularData = new FormData()

            if (this.subeID > 0) {
                kutularData.append('MusteriNo[]', this.subeID)
            } else {
                this.subeler.forEach(sube => {
                    kutularData.append('MusteriNo[]', sube.MusteriID)
                })
            }

            api
             .subeKutularGetir(kutularData)
             .then(resp => resp.json())
             .then(data => {
                 this.subeGelirleri = Object.keys(data).map(key => {
                     
                     return {
                         zaman: this.keyFormatla(key),
                         toplamGelir: data[key]['Gelir'],
                         toplamGider: data[key]['Gider'],
                         gelir: paraFormatla(data[key]['Gelir']),
                         gider: paraFormatla(data[key]['Gider'])
                     }
                 })
                 
             })
        },
        subelerGetir() {
            const subelerData = new FormData()

            const { MusteriNo } = api.getUser()

            subelerData.append('MusteriNo', MusteriNo)

            api
            .subelerGetir(subelerData)
            .then(resp => resp.json())
            .then(data => {
                this.subeler = data[0]

                this.subeKutularGetir()
            })
        },
        keyFormatla(key) {
            
            let zamanAdi = ''

            switch (key) {
                case 'bugun':
                    zamanAdi = 'Bugün'
                    break;
                case 'buhafta':
                    zamanAdi = 'Bu Hafta'
                    break;
                case 'buay':
                    zamanAdi = 'Bu Ay'
                    break;
                case 'dun':
                    zamanAdi = 'Dün'
                    break;
                case 'gecenhafta':
                    zamanAdi = 'Geçen Hafta'
                    break;
                case 'gecenay':
                    zamanAdi = 'Geçen Ay'
                    break;
                default:
                    break;
            }

            return zamanAdi
        }
    },

  }
</script>
<style>
</style>
