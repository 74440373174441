<template>
    <div>
        <base-header type="gradient-dark" class="pb-3 pb-3 pt-3 pt-md-5"></base-header>
        <div class="container-fluid mb-2 mt-2">
            <div class="row">
                <div class="col-12">
                    <masa-doluluk-orani />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import MasaDolulukOrani from './Dashboard/MasaDolulukOrani';

export default {
    name: 'MasaDolulukOranlari',
    components: {
        MasaDolulukOrani
    },
    data() {
        return {

        }
    }
}
</script>